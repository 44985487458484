@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;

.tile {
  display: grid;
  gap: 1.5em;
  align-items: flex-start;

  @media (min-width: 470px) {
    grid-template-columns: 8fr 4fr;
  }

  &.state {
    border-left-width: .5em;
    border-left-style: solid;
    padding: 1em;

    @media (min-width: $md) {
      border-left-width: 1em;
    }
  }
}

.tile__intro {
  display: grid;
  gap: 1.5em;

  img {
    display: none;

    @media (min-width: $sm) {
      display: block;
      margin: 0 auto;
      width: 45%;
    }
  }
}

.tile__items {
  display: grid;
  gap: 0.5em;
}

.tile__item {
  @include font(.875em);
  display: grid;
  grid-template-columns: 5fr 7fr;
  gap: .5em;

  .desc {
    display: flex;
    align-items: flex-start;
    gap: 1em;
    font-weight: 700;
  }

  &.separator {
    height: 1px;
    background-color: $color-grey-3;
    margin: .2em 0;
  }

  .title {
    color: $color-grey-4;

    span {
      display: block;
      font-size: .75em;
    }
  }
}
